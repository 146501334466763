.bba-icon {
  font-size: 20px;
  cursor: pointer;
}

.spinner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 10%);
}

.spinner {
  position: absolute;
  top: 50%;
}


.log-big {
  width: 85%;
  height: auto
}

.log-small {
  width: 33px;
  height: auto;
}


@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: none !important;
    padding: 0.25em !important;
  }

  .table>:not(:first-child) {
    border-top: none !important;
    background-color: white !important;
  }
}

.react-switch-bg {
  height: 22px !important;
  width: 50px !important;
}

.react-switch-handle {
  height: 20px !important;
  width: 20px !important;
}

.form-control-email {
  margin-top: 5px;
}

.invalid-feedback {
  display: block !important;
}

.pagination-button-size {
  padding: 6px 12px !important;
}

.switch-inline {
  display: inline !important;
  float: right !important;
}

.mt-2-px {
  margin-top: 2px !important;
}

.mt-10-px {
  margin-top: 10px !important;
}

.menu-show-transition {
  position: absolute;
  left: -100px;
  /* SET THE SLIDER TO BE OFFSCREEN INITIALLY */
  width: 100%;
  height: 100%;
  background: blue;
  transition: 1s;
  // transition-property: width;
  // transition-duration: 1s;
  // width: 100%;
  // height: 100%
}

.menu-hide-transition {
  transition-property: width;
  transition-duration: 2s;
  width: 0px;
  height: 0px
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-align-right {
  text-align: right;
}

.sub-checkbox {
  margin-left: -8px !important;
  margin-right: 5px !important;
  float: none !important;
}

.permission-modal-section {
  height: calc(100vh - 210px);
  overflow-x: hidden;
  overflow-y: scroll;
}